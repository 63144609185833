.randomArticlesButton {
  /* grid-area: randomArticlesButton; */
  height: 2rem;
  padding: 8px;
  margin: auto;
  border-radius: 15px;
  border: none;
  background-color: #60a5fa;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}
.table {
  grid-area: table;
}

.container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto;
  grid-template-areas:
    '. searchBar searchButton .'
    '. randomArticlesButton randomArticlesButton .'
    'table table table table';
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  text-align: center;
}

.footerItem {
  padding: 0.3rem;
}
