.collectionCard {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px;
    cursor: pointer;
}

.profileImage {
    border: 1px solid #DADADA;
    box-sizing: border-box;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 200px;
    width: 120px;
    height: 120px;
}

.value {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 45px;
    text-align: center;
    color: #000000;
    margin: 20px 0 4.5px 0;
}

.title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin: 20px 0 4.5px 0;
}

.subtitle {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    margin: 4.5px 0;

    color: #555555;
}

.subtitle2 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    margin: 8px 0;

    color: #888;
}