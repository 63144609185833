.searchBar {
  display: inline;
  margin-top: 2rem;
  border: none;
  background-color: white;
  color: black;
  font-size: 1.4rem;
  height: 3rem;
  width: 30rem;
  border: none;
  padding-left: 0.3rem;
  border-bottom: 0.2rem solid black;
  grid-area: searchBar;
}

.searchBar::placeholder {
  color: black;
  font-size: 1.4rem;
  width: 30rem;
}

.searchBar:focus {
  outline: none;
}

/* .searchButton {
  grid-area: searchButton;
  height: 2rem;
  padding: 8px;
  margin: auto;
  border-radius: 15px;
  border: none;
  background-color: #60a5fa;
  color: white;
  font-size: 1rem;
  font-weight: bold;
} */
